const getName = {
    "01_微笑.gif": "微笑",
    "02_色.gif": "色",
    "03_发呆.gif": "发呆",
    "04_得意.gif": "得意",
    "05_流泪.gif": "流泪",
    "06_害羞.gif": "害羞",
    "07_闭嘴.gif": "闭嘴",
    "08_睡.gif": "睡",
    "09_大哭.gif": "大哭",
    "10_尴尬.gif": "尴尬",
    "11_傲慢.gif": "傲慢",
    "11_发怒.gif": "发怒",
    "12_调皮.gif": "调皮",
    "13_呲牙.gif": "呲牙",
    "14_难过.gif": "难过",
    "15_酷.gif": "酷",
    "16_抓狂.gif": "抓狂",
    "17_吐.gif": "吐",
    "18_偷笑.gif": "偷笑",
    "19_可爱.gif": "可爱",
    "20_白眼.gif": "白眼",
    "21_饥饿.gif": "饥饿",
    "22_困.gif": "困",
    "23_惊恐.gif": "惊恐",
    "24_流汗.gif": "流汗",
    "25_憨笑.gif": "憨笑",
    "26_悠闲.gif": "悠闲",
    "27_奋斗.gif": "奋斗",
    "28_咒骂.gif": "咒骂",
    "29_疑问.gif": "疑问",
    "30_嘘.gif": "嘘",
    "31_晕.gif": "晕",
    "32_折磨.gif": "折磨",
    "33_衰.gif": "衰",
    "34_骷髅.gif": "骷髅",
    "35_敲打.gif": "敲打",
    "36_再见.gif": "再见",
    "37_猪头.gif": "猪头",
    "38_拥抱.gif": "拥抱",
    "39_蛋糕.gif": "蛋糕",
    "40_闪电.gif": "闪电",
    "41_炸弹.gif": "炸弹",
    "42_砍刀.gif": "砍刀",
    "43_足球.gif": "足球",
    "44_便便.gif": "便便",
    "45_咖啡.gif": "咖啡",
    "46_米饭.gif": "米饭",
    "47_药.gif": "药",
    "48_玫瑰.gif": "玫瑰",
    "49_凋谢.gif": "凋谢",
    "50_爱心.gif": "爱心",
    "51_心碎.png": "心碎",
    "52_赞.gif": "赞",
    "53_踩.gif": "踩",
    "54_握手.gif": "握手",
    "55_胜利.gif": "胜利",
    "56_抱拳.gif": "抱拳",
    "57_勾引.gif": "勾引",
    "58_拳头.gif": "拳头",
    "59_冷汗.gif": "冷汗",
    "60_擦汗.gif": "擦汗",
    "61_抠鼻.gif": "抠鼻",
    "62_鼓掌.gif": "鼓掌",
    "63_糗大了.gif": "糗大了",
    "64_坏笑.gif": "坏笑",
    "65_左哼哼.gif": "左哼哼",
    "66_右哼哼.gif": "右哼哼",
    "67_哈欠.gif": "哈欠",
    "68_鄙视.gif": "鄙视",
    "69_委屈.gif": "委屈",
    "70_快哭了.gif": "快哭了",
    "71_阴险.gif": "阴险",
    "72_亲亲.gif": "亲亲",
    "73_吓.gif": "吓",
    "74_可怜.gif": "可怜",
    "75_菜刀.gif": "菜刀",
    "76_啤酒.gif": "啤酒",
    "77_篮球.gif": "篮球",
    "78_乒乓球.gif": "乒乓球",
    "79_嘴唇.gif": "嘴唇",
    "80_瓢虫.gif": "瓢虫",
    "81_差劲.gif": "差劲",
    "82_爱你.gif": "爱你",
    "83_NO.gif": "NO",
    "84_OK.gif": "OK",
    "85_泪奔.gif": "泪奔",
    "86_无奈.gif": "无奈",
    "87_卖萌.gif": "卖萌",
    "88_皱眉.gif": "皱眉",
    "89_吐血.gif": "吐血",
    "90_滑稽.gif": "滑稽",
    "91_doge.gif": "doge",
    "92_惊喜.gif": "惊喜",
    "93_骚扰.gif": "骚扰",
    "94_笑哭.gif": "笑哭",
    "95_俺最美.gif": "俺最美"
};
const getKey = {
    "微笑": "01_微笑.gif",
    "色": "02_色.gif",
    "发呆": "03_发呆.gif",
    "得意": "04_得意.gif",
    "流泪": "05_流泪.gif",
    "害羞": "06_害羞.gif",
    "闭嘴": "07_闭嘴.gif",
    "睡": "08_睡.gif",
    "大哭": "09_大哭.gif",
    "尴尬": "10_尴尬.gif",
    "傲慢": "11_傲慢.gif",
    "发怒": "11_发怒.gif",
    "调皮": "12_调皮.gif",
    "呲牙": "13_呲牙.gif",
    "难过": "14_难过.gif",
    "酷": "15_酷.gif",
    "抓狂": "16_抓狂.gif",
    "吐": "17_吐.gif",
    "偷笑": "18_偷笑.gif",
    "可爱": "19_可爱.gif",
    "白眼": "20_白眼.gif",
    "饥饿": "21_饥饿.gif",
    "困": "22_困.gif",
    "惊恐": "23_惊恐.gif",
    "流汗": "24_流汗.gif",
    "憨笑": "25_憨笑.gif",
    "悠闲": "26_悠闲.gif",
    "奋斗": "27_奋斗.gif",
    "咒骂": "28_咒骂.gif",
    "疑问": "29_疑问.gif",
    "嘘": "30_嘘.gif",
    "晕": "31_晕.gif",
    "折磨": "32_折磨.gif",
    "衰": "33_衰.gif",
    "骷髅": "34_骷髅.gif",
    "敲打": "35_敲打.gif",
    "再见": "36_再见.gif",
    "猪头": "37_猪头.gif",
    "拥抱": "38_拥抱.gif",
    "蛋糕": "39_蛋糕.gif",
    "闪电": "40_闪电.gif",
    "炸弹": "41_炸弹.gif",
    "砍刀": "42_砍刀.gif",
    "足球": "43_足球.gif",
    "便便": "44_便便.gif",
    "咖啡": "45_咖啡.gif",
    "米饭": "46_米饭.gif",
    "药": "47_药.gif",
    "玫瑰": "48_玫瑰.gif",
    "凋谢": "49_凋谢.gif",
    "爱心": "50_爱心.gif",
    "心碎": "51_心碎.png",
    "赞": "52_赞.gif",
    "踩": "53_踩.gif",
    "握手": "54_握手.gif",
    "胜利": "55_胜利.gif",
    "抱拳": "56_抱拳.gif",
    "勾引": "57_勾引.gif",
    "拳头": "58_拳头.gif",
    "冷汗": "59_冷汗.gif",
    "擦汗": "60_擦汗.gif",
    "抠鼻": "61_抠鼻.gif",
    "鼓掌": "62_鼓掌.gif",
    "糗大了": "63_糗大了.gif",
    "坏笑": "64_坏笑.gif",
    "左哼哼": "65_左哼哼.gif",
    "右哼哼": "66_右哼哼.gif",
    "哈欠": "67_哈欠.gif",
    "鄙视": "68_鄙视.gif",
    "委屈": "69_委屈.gif",
    "快哭了": "70_快哭了.gif",
    "阴险": "71_阴险.gif",
    "亲亲": "72_亲亲.gif",
    "吓": "73_吓.gif",
    "可怜": "74_可怜.gif",
    "菜刀": "75_菜刀.gif",
    "啤酒": "76_啤酒.gif",
    "篮球": "77_篮球.gif",
    "乒乓球": "78_乒乓球.gif",
    "嘴唇": "79_嘴唇.gif",
    "瓢虫": "80_瓢虫.gif",
    "差劲": "81_差劲.gif",
    "爱你": "82_爱你.gif",
    "NO": "83_NO.gif",
    "OK": "84_OK.gif",
    "泪奔": "85_泪奔.gif",
    "无奈": "86_无奈.gif",
    "卖萌": "87_卖萌.gif",
    "皱眉": "88_皱眉.gif",
    "吐血": "89_吐血.gif",
    "滑稽": "90_滑稽.gif",
    "doge": "91_doge.gif",
    "惊喜": "92_惊喜.gif",
    "骚扰": "93_骚扰.gif",
    "笑哭": "94_笑哭.gif",
    "俺最美": "95_俺最美.gif"
}
const list = "01_微笑.gif,02_色.gif,03_发呆.gif,04_得意.gif,05_流泪.gif,06_害羞.gif,07_闭嘴.gif,08_睡.gif,09_大哭.gif,10_尴尬.gif,11_傲慢.gif,11_发怒.gif,12_调皮.gif,13_呲牙.gif,14_难过.gif,15_酷.gif,16_抓狂.gif,17_吐.gif,18_偷笑.gif,19_可爱.gif,20_白眼.gif,21_饥饿.gif,22_困.gif,23_惊恐.gif,24_流汗.gif,25_憨笑.gif,26_悠闲.gif,27_奋斗.gif,28_咒骂.gif,29_疑问.gif,30_嘘.gif,31_晕.gif,32_折磨.gif,33_衰.gif,34_骷髅.gif,35_敲打.gif,36_再见.gif,37_猪头.gif,38_拥抱.gif,39_蛋糕.gif,40_闪电.gif,41_炸弹.gif,42_砍刀.gif,43_足球.gif,44_便便.gif,45_咖啡.gif,46_米饭.gif,47_药.gif,48_玫瑰.gif,49_凋谢.gif,50_爱心.gif,51_心碎.png,52_赞.gif,53_踩.gif,54_握手.gif,55_胜利.gif,56_抱拳.gif,57_勾引.gif,58_拳头.gif,59_冷汗.gif,60_擦汗.gif,61_抠鼻.gif,62_鼓掌.gif,63_糗大了.gif,64_坏笑.gif,65_左哼哼.gif,66_右哼哼.gif,67_哈欠.gif,68_鄙视.gif,69_委屈.gif,70_快哭了.gif,71_阴险.gif,72_亲亲.gif,73_吓.gif,74_可怜.gif,75_菜刀.gif,76_啤酒.gif,77_篮球.gif,78_乒乓球.gif,79_嘴唇.gif,80_瓢虫.gif,81_差劲.gif,82_爱你.gif,83_NO.gif,84_OK.gif,85_泪奔.gif,86_无奈.gif,87_卖萌.gif,88_皱眉.gif,89_吐血.gif,90_滑稽.gif,91_doge.gif,92_惊喜.gif,93_骚扰.gif,94_笑哭.gif,95_俺最美.gif";

export{
    getName,
    getKey,
    list
}