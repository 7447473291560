<template>
  <div class="comment-input-main">
    <div class="comment-input" :id="`commentInput${pid}`">
      <el-input
        type="textarea"
        rows="4"
        class="input"
        v-model="commentValue"
        :maxlength="200"
        @blur="blur"
        placeholder="说说你的看法"
      ></el-input>
      <div class="b">
        <div class="down">
          <div class="gif-list">
            <div class="gif">
              <div
                class="item"
                @click="check(item)"
                v-for="item in gif.split(',')"
                :key="item"
              >
                <img :title="getName[item]" :src="`/gif/${item}`" alt="" />
              </div>
            </div>
          </div>
          <span slot="reference">
            <i class="iconfont icon-expressions"></i
          ></span>
        </div>
        <div class="btn">
          <span>{{ commentValue.length }}/200</span>
          <el-button
            type="primary"
            size="mini"
            :loading="cLoad"
            @click="goComment()"
            >评论</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getName, list } from "@/common/phiz";
export default {
  props: {
    value: {
      default: "",
      type: Number | String,
    },
    pid: {
      default: "",
      type: Number | String,
    },
  },
  comments: {
    // commentValue: {
    //   get() {
    //     return this.value;
    //   },
    //   set(val) {
    //     this.$emit("input", val);
    //   },
    // },
  },
  data() {
    return {
      getName,
      commentValue: "",
      cLoad: false,
      gif:list,
      index:0
    };
  },
  methods: {
    blur(){
      this.index = this.getPosition(
        $(`#commentInput${this.pid} .input textarea`)[0]
      );
      console.log(this.index);
    },
    check(item) {
      if (this.commentValue.length > 195) return;
      let img = `[${getName[item]}]`;
      let index = this.getPosition(
        $(`#commentInput${this.pid} .input textarea`)[0]
      );
      this.commentValue = `${this.commentValue.substring(
        0,
        this.index
      )}${img}${this.commentValue.substring(this.index, this.commentValue.length)}`;
      this.index += img.length;
    },
    //输入框获取光标
    getPosition(element) {
      let cursorPos = 0;
      if (document.selection) {
        //IE
        var selectRange = document.selection.createRange();
        selectRange.moveStart("character", -element.value.length);
        cursorPos = selectRange.text.length;
      } else if (element.selectionStart || element.selectionStart == "0") {
        cursorPos = element.selectionStart;
      }
      return cursorPos;
    },
    goComment() {
      if (this.commentValue == "") {
        this.$message({
          message: "请输入评论内容",
          type: "error",
          duration: 1500,
        });
        return;
      }
      this.cLoad = true;
      this.$api
        .post(
          "/api/v1/voteMessage-" + this.$route.query.id,
          this.$common.getFormData({
            message: this.commentValue,
            parentGuestbookId: this.pid,
          })
        )
        .then((res) => {
          this.cLoad = false;
          if (res.code == 1) {
            this.commentValue = "";
            $(`#commentInput${this.pid} .input`).val("");
            this.$emit("success");
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.cLoad = false;
        });
    },
  },
};
</script>