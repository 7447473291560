<template>
  <div class="comment">
    <h3>评论</h3>
    <comment-input @success="success" pid="0" />
    <div class="xcp-list">
      <h2 class="xcp-list-title">评论列表（{{ commentList.length }}条）</h2>
      <div class="xcp-list-list">
        <div>
          <div class="xcp-item" v-for="item in commentList" :key="item.id">
            <div class="left">
              <div class="x-avatar">
                <el-image
                  :src="'https://www.yizao2025.com' + item.userImages"
                ></el-image>
              </div>
            </div>
            <div class="right">
              <div class="user-bar">
                <h5 class="user-bar-uname">{{ item.userName }}</h5>
              </div>

              <div class="x-interact-rich-text rich-text">
                <span class="type-text" v-html="item.content"></span>
              </div>

              <div class="interact-bar">
                <div class="interact-bar-left">
                  <span class="time" v-if="item.updateDate">{{
                    $common.showDate(item.updateDate)
                  }}</span>
                </div>
                <div class="interact-bar-right">
                  <div class="reply">
                    <i class="iconfont icon-pinglun"></i>
                    <span class="reply-text" @click="goCommentShow(item)"
                      >回复</span
                    >
                  </div>
                  <div class="like" @click="upData(item)">
                    <i class="iconfont icon-dianzan"></i>
                    <span class="like-text">{{ item.hits || "赞" }}</span>
                  </div>
                </div>
              </div>
              <comment-input
                :id="`commentInputItem${item.id}`"
                class="comment-input-item"
                @success="success"
                :pid="item.id"
              />
              <div v-if="item.subpage.count > 0">
                <div class="xcp-list">
                  <div class="xcp-list-list is-second">
                    <div v-for="items in item.subpage.list" :key="items.id">
                      <div class="xcp-item" data-reply-id="1116247208708472014">
                        <div class="left">
                          <div class="x-avatar is-small">
                            <el-image
                              :src="
                                'https://www.yizao2025.com' + item.userImages
                              "
                            ></el-image>
                          </div>
                        </div>
                        <div class="right">
                          <div class="user-bar">
                            <h5 class="user-bar-uname">{{ items.userName }}</h5>
                          </div>

                          <div
                            class="x-interact-rich-text rich-text"
                            data-reply-id="1116247208708472014"
                          >
                            <span
                              class="type-text"
                              v-html="items.content"
                            ></span>
                          </div>

                          <div
                            class="interact-bar"
                            data-reply-id="1116247208708472014"
                          >
                            <div class="interact-bar-left">
                              <span class="time" v-if="items.updateDate">{{
                                $common.showDate(items.updateDate)
                              }}</span>
                            </div>
                            <div class="interact-bar-right">
                              <!-- <div class="reply">
                                <i class="iconfont icon-pinglun"></i>
                                <span class="reply-text">回复</span>
                              </div> -->
                              <div class="like" @click="upData(items)">
                                <i class="iconfont icon-dianzan"></i>
                                <span class="like-text">{{
                                  items.hits || "赞"
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      item.subpage.count > 3 &&
                      item.subpage.list.length <= 3 &&
                      twoLoad != 1
                    "
                    class="xcp-list-loader is-second"
                    @click="getTwoData(item)"
                  >
                    <span>全部{{ item.subpage.count }}条回复</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                  <div v-if="twoLoad == 1" class="xcp-list-loader is-second">
                    <span class="el-icon-loading"></span> <span>加载中...</span>
                  </div>
                  <div
                    v-if="item.subpage.list.length > 3"
                    class="xcp-list-loader is-second"
                    @click="upTwoData(item)"
                  >
                    <span>收起回复</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="xcp-list-loader"
        @click="getData()"
        data-parent-id="0"
        v-if="dataLoad != 1"
      >
        <span>{{ moreText }}</span
        ><i v-if="dataLoad == 0" class="el-icon-arrow-down"></i>
      </div>
      <div
        class="xcp-list-loader"
        @click="getData()"
        data-parent-id="0"
        v-if="dataLoad == 1"
      >
        <span class="el-icon-loading"></span> <span>加载中...</span>
      </div>
    </div>
  </div>
</template>
<script>
import commentInput from "./input";
import { getKey } from "@/common/phiz";
export default {
  components: { commentInput },
  data() {
    return {
      commentList: [],
      pageIndex: 1,
      moreText: "查看更多评论",
      dataLoad: 0,
      activeItem: {},
      twoLoad: 0,
    };
  },
  methods: {
    goCommentShow(item) {
      this.activeItem = item;
      let obj = $(`#commentInputItem${item.id}`);
      if (obj.css("display") == "none") {
        obj.slideDown(100);
      } else {
        obj.slideUp(100);
      }
    },
    success() {
      this.dataLoad = 1;
      this.getData();
      let obj = $(`#commentInputItem${this.activeItem.id}`);
      obj.hide();
    },
    upHits(data, item) {
      data = data.map((items) => {
        if (items.subpage && items.subpage.count > 0) {
          items.subpage.list = this.upHits(items.subpage.list, item);
        }
        if (items.id == item.id) {
          items.hits++;
        }
        return items;
      });
      return data;
    },
    upData(item) {
      this.$api
        .post("/api/v1/updateGuestBookHitsNumAddOne-" + item.id)
        .then((res) => {
          this.commentList = this.upHits(this.commentList, item);
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        });
    },
    cut(data) {
      data = data.map((item) => {
        if (item.subpage && item.subpage.count > 0) {
          item.subpage.list = this.cut(item.subpage.list);
        }
        let arr = item.content.match(/\[(\S*?)]/g);
        arr && arr.length
          ? arr.forEach((items, index) => {
              let name = items
                .replace("[", "")
                .replace("]", "")
                .replace("_" + (index + 1), "");
              item.content = item.content.replace(
                items,
                `<img class="type-image" src="/gif/${getKey[name]}"  />`
              );
            })
          : null;
        return item;
      });
      return data;
    },
    upTowData(data, item, list) {
      data = data.map((items) => {
        if (items.subpage && items.subpage.count > 0) {
          items.subpage.list = this.upTowData(items.subpage.list, item, list);
        }
        if (items.id == item.id) {
          items.subpage.list = list;
        }
        return items;
      });
      return data;
    },
    upTwoData(item) {
      this.commentList = this.upTowData(
        this.commentList,
        item,
        item.subpage.list.filter((item, index) => index < 3)
      );
    },
    getTwoData(item) {
      this.twoLoad = 1;
      this.$api
        .post(
          "/api/v1/getVoteGuestbookList",
          this.$common.getFormData({
            studentId: this.$route.query.id,
            parentCode: item.id,
            pageNo: 1,
            pageSize: 10000,
          })
        )
        .then((res) => {
          this.twoLoad = 0;
          this.commentList = this.upTowData(
            this.commentList,
            item,
            res.data.list
          );
        })
        .catch((err) => {
          this.twoLoad = 2;
        });
    },
    getData() {
      if (this.dataLoad == 2) return;
      this.dataLoad = 1;
      this.$api
        .post(
          "/api/v1/getVoteGuestbookList",
          this.$common.getFormData({
            studentId: this.$route.query.id,
            pageNo: this.pageIndex,
            pageSize: 10000,
          })
        )
        .then((res) => {
          this.dataLoad = 0;
          this.commentList = this.cut(res.data.list);
          if (res.data.list.length < res.data.pageSize) {
            this.moreText = "没有更多了";
            this.dataLoad = 2;
          } else {
            this.moreText = "查看更多评论";
            this.pageIndex++;
          }
        })
        .catch((err) => {
          this.dataLoad = 2;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss">
.comment {
  color: #f5f5f5;
  margin-top: 30px;
  & > h3 {
    font-size: 20px;
    font-weight: 500;
  }
  .comment-input-main {
    padding: 20px 0;
  }
  .comment-input {
    border-radius: 5px;
    border: 1px solid #f5f5f5;
    color: #f5f5f5;
    padding: 10px;
    .input {
      font-size: 16px;
      .el-textarea__inner {
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        color: #f5f5f5;
        resize: none;
      }
    }
    .b {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .down {
        position: relative;
        width: 20%;
        &:hover {
          .gif-list {
            display: block;
          }
        }
        i.iconfont {
          color: #f5f5f5;
          font-size: 20px;
          margin-top: 10px;
          display: block;
          cursor: pointer;
        }
        .gif-list {
          display: none;
          position: absolute;
          left: 0;
          top: 100%;
          width: 500px;
          height: 200px;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 10px;
          z-index: 99;
          border-radius: 10px;
          overflow: hidden;
          .gif {
            display: flex;
            flex-wrap: wrap;
            overflow-y: auto;
            height: 200px;
            .item {
              width: 30px;
              cursor: pointer;
              margin: 2px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
      .btn {
        & > span {
          margin-right: 20px;
          font-size: 14px;
        }
      }
    }
  }
}
.comment-input-item {
  display: none;
}
.gif-popover {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8) !important;
  .el-popover__title {
    font-weight: 600;
  }
  .popper__arrow {
    border-top-color: rgba(255, 255, 255, 0.8) !important;
    &::after {
      border-top-color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}
.xcp-list * {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
h5 {
  margin: 0;
}
.xcp-list-title {
  margin: 30px 0 0;
  font-size: 20px;
  line-height: 1;
  color: #f5f5f5;
  font-weight: 500;
}
.xcp-list-loader {
  margin-top: 64px;
  height: 42px;
  font: 14px PingFangSC-Regular;
  text-align: center;
  line-height: 42px;
  color: #f5f5f5;
  border-radius: 6px;
  cursor: pointer;
}
.xcp-list-loader.no-more {
  background: transparent;
}
.xcp-list-loader.is-second {
  margin-top: 30px;
  padding-left: 55px;
  height: auto;
  text-align: left;
  font: 13px/1 PingFangSC-Semibold;
  font-size: 13px;
  color: #f5f5f5;
  background: transparent;
}
.xcp-list-loader i {
  margin-left: 5px;
}
.x-avatar {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.user-bar-uname {
  margin-top: 5px;
}
.x-avatar.is-small {
  width: 39px;
  height: 39px;
}
.xplaceholder-avatar- {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(https://b.bdstatic.com/searchbox/icms/searchbox/img/xcp-bd-logo.png)
    50% 50% no-repeat;
  background-size: 50% 50%;
}
.x-avatar-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.x-avatar-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 300%;
  height: 300%;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  -webkit-transform: scale(0.333333);
  transform: scale(0.333333);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.x-avatar-vip {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 16.5px;
  height: 16.5px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.x-avatar .is-small-vip {
  width: 13.2px;
  height: 13.2px;
}
.xcp-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 28px;
  font-size: 14px;
  color: #f5f5f5;
}
.xcp-item .left {
  width: 64px;
}
.is-second .xcp-item .left {
  width: 55px;
}
.xcp-item .right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 1%;
}
.is-second .xcp-item {
  padding-top: 28px;
}
.x-interact-rich-text {
  padding-top: 10px;
  font: 14px/22px PingFangSC-Regular;
  text-align: justify;
  color: #f5f5f5;
  word-break: break-all;
  word-wrap: break-word;
}
.x-interact-rich-text > a {
  text-decoration: none;
}
.x-interact-rich-text .type-at,
.x-interact-rich-text .type-image,
.x-interact-rich-text .type-link,
.x-interact-rich-text .type-topic,
.x-interact-rich-text .type-video,
.x-interact-rich-text .type-vote {
  color: #4367b4;
  cursor: pointer;
}
.type-image {
  width: 30px;
  vertical-align: middle;
  margin: 0 2px;
}
.x-interact-rich-text .invalid-href.type-at {
  color: #858585;
  pointer-events: none;
}
.x-interact-rich-text .type-emoji {
  width: 14px;
  margin-top: -4px;
  background-size: 100%;
}
.x-interact-rich-text .type-emoji,
.x-interact-rich-text .type-label {
  display: inline-block;
  height: 14px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  vertical-align: middle;
}
.x-interact-rich-text .type-label {
  width: 42px;
  margin-top: -2px;
  background-size: cover;
}
.x-interact-rich-text-is-author {
  padding: 0 0.03rem;
  font-size: 0.1rem;
  color: #4d6df2;
  background: #e4eaff;
  border-radius: 0.03rem;
}
.x-interact-rich-text-colon,
.x-interact-rich-text-uname {
  color: #858585;
}
.interact-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 8px;
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #9195a3;
}
.interact-bar:hover .delete,
.interact-bar:hover .report {
  display: block;
}
.interact-bar > * {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.interact-bar-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.interact-bar-left .delete,
.interact-bar-left .report {
  display: none;
  margin-left: 10px;
  cursor: pointer;
}
.interact-bar-right {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #f5f5f5;
}
.interact-bar-right .like:hover {
  color: $c2;
}
.interact-bar-right .like,
.interact-bar-right .reply {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.interact-bar-right .icon {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.interact-bar-right .reply {
  cursor: pointer;
}

.interact-bar-right .iconfont {
  cursor: pointer;
  margin-left: 16px;
  margin-right: 5px;
}
.interact-bar-right .like.liked {
  color: #f33;
}
.interact-bar-right .icon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}
.interact-bar .custom-dialog {
  position: absolute;
  left: 0;
  top: 56px;
  z-index: 1;
}
</style>