<template>
  <div class="show-detail">
    <div class="content">
      <div class="d-l">
        <div class="title" v-if="listVedio.length">视频作品</div>
        <div class="item anime" v-for="item in listVedio" :key="item.id">
          <video
            :src="item.fileUrl"
            controls
            playsinline
            preload="auto"
            x5-playsinline
            webkit-playsinline
            controlsList="nodownload"
          ></video>
        </div>
        <div class="title" v-if="listVedio.length">图片作品</div>
        <div class="item anime" v-for="item in showList" :key="item.id">
          <el-image
            :id="item.id"
            :src="item.fileUrl"
            :preview-src-list="srcList(item)"
            fit="none"
          >
          </el-image>
          <p @click="showItem(item)"><i class="iconfont icon-fangda"></i></p>
        </div>
        <div class="anime">
          <comment />
        </div>
      </div>
      <div class="d-r">
        <div class="title anime">{{ detail.worksName }}</div>
        <div class="b anime">
          <p><i class="iconfont icon-liulan"></i>{{ detail.viewCount || 0 }}</p>
          <p class="pointer" @click="hits">
            <i class="iconfont icon-dianzan"></i>{{ detail.hits || 0 }}
          </p>
          <p class="pointer" @click="goComment">
            <i class="iconfont icon-pinglun"></i>{{ detail.messageCount || 0 }}
          </p>
          <div class="b-btn">
            <el-button
              type="primary"
              size="mini"
              @click="vote"
              :loading="btnLoad"
              >给他投票({{ detail.ticket }})</el-button
            >
          </div>
        </div>
        <div class="text anime" v-html="detail.copyrightContent"></div>
        <div class="share anime">
          <h3>分享至：</h3>
          <share
            :url="`https://www.yizao2025.com/competition/showDetail?id=${detail.id}`"
            :image="detail.firstImage"
            :title="detail.worksName"
            :desc="detail.decoration"
            :top="false"
            :id="detail.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import comment from "./components/comment";
import share from "@/components/share";
export default {
  components: { share, comment },
  data() {
    return {
      showList: [],
      detail: {},
      btnLoad: false,
      listVedio: [],
    };
  },
  methods: {
    srcList(item) {
      let before = [];
      let after = [];
      let is = false;
      this.showList.forEach((items) => {
        if (items.id == item.id) {
          is = true;
        }
        if (!is) {
          before.push(items.fileUrl);
        }
        if (is) {
          after.push(items.fileUrl);
        }
      });
      return [item.fileUrl, ...after, ...before];
    },
    showItem(item) {
      $("#" + item.id).click();
    },
    goComment() {
      $("body,html").animate(
        { scrollTop: $(".comment").offset().top - 150 },
        300
      );
    },
    hits() {
      this.$api
        .post("/api/v1/updateStudentHitsNumAddOne-" + this.detail.id)
        .then((res) => {
          if (res.code == 1) {
            this.detail.hits++;
            this.$message({
              message: res.message,
              type: res.code == 1 ? "success" : "error",
              duration: 1500,
            });
          }
        });
    },
    vote() {
      this.btnLoad = true;
      this.$api
        .post(
          `/api/v1/vote-${this.detail.id}`,
          this.$common.getFormData({
            id: this.detail.id,
          })
        )
        .then((res) => {
          this.btnLoad = false;
          if (res.code == 1) {
            this.detail = res.data.yzStudentInfo;
          }
          this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
        })
        .catch((err) => {
          this.btnLoad = false;
        });
    },
    getData() {
      this.$api
        .post(`/api/v1/getStudentInfoDetailById?id=${this.$route.query.id}`)
        .then((res) => {
          this.showList = res.data.listImages||[];
          this.listVedio = res.data.listVedio||[];
          res.copyrightContent = res.data.copyrightContent&&res.data.copyrightContent.replaceAll(
            "http://",
            "https://"
          );
          this.$api
            .post("/api/v1/updateVoteViewCount-" + this.$route.query.id)
            .then((res) => console.log(res));
          this.detail = res.data;
          this.$nextTick(() => {
            this.$store.commit("anime");
          });
        });
    },
  },
  mounted() {
    this.getData();
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
video::-webkit-media-controls {
  overflow: hidden !important;
}
video::-webkit-media-controls-enclosure {
  width: calc(100% + 32px);
  margin-left: auto;
}
.show-detail {
  padding: 4vw 0 0;
  .content {
    display: flex;
    justify-content: space-between;
    .d-l {
      width: 68%;
      .title {
        font-size: 24px;
        color: #f5f5f5;
        text-align: center;
        margin-bottom: 30px;
      }
      .item {
        margin-bottom: 30px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        &:hover {
          p {
            opacity: 1;
          }
        }
        .el-image {
          border-radius: 20px;
          display: block;
          img {
            top: 0 !important;
            left: 0;
            transform: translate(0, 0) !important;
          }
        }
        video {
          width: 100%;
        }
        p {
          transition: 0.3s all;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 9;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.4);
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
      }
    }
    .d-r {
      width: 28%;
      color: #f5f5f5;
      .title {
        font-size: 24px;
        line-height: 40px;
      }
      .b {
        display: flex;
        margin-top: 30px;
        p {
          width: 20%;
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          &.pointer {
            cursor: pointer;
            &:hover {
              color: $c2;
            }
          }
          i {
            margin-right: 10px;
          }
        }
        .b-btn {
          width: 40%;
          text-align: right;
          .el-button {
          }
        }
        padding-bottom: 20px;
        border-bottom: 1px solid #666666;
      }
      .text {
        margin-top: 20px;
        min-height: 20vh;
        img {
          max-width: 100%;
          height: auto !important;
        }
        padding-bottom: 20px;
      }
      & > .share {
        margin-top: 30px;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        .share {
          margin-top: 20px;
        }
      }
      .btn {
        margin-top: 80px;
        text-align: center;
      }
    }
  }
}
</style>